import useInput2 from "../hooks/use-input2";
import Swal from "sweetalert2";
import emailjs from "emailjs-com";
import { useRef } from "react";

const SERVICE_ID = "service_gwyzb5u";
const TEMPLATE_ID = "template_sth4ra9";
const USER_ID = "XcxEQFzRZ3CGkS071";

const BasicForm = (props) => {
  const form = useRef();

  const formSubmitHandler = (event) => {
    event.preventDefault();
    emailjs.sendForm(SERVICE_ID, TEMPLATE_ID, form.current, USER_ID).then(
      (result) => {
        console.log(result.text);
        Swal.fire({
          icon: "success",
          title: "Message Sent Successfully",
        });
      },
      (error) => {
        console.log(error.text);
        Swal.fire({
          icon: "error",
          title: "Ooops, something went wrong",
          text: error.text,
        });
      }
    );
    resetEmail();
    resetMessage();
    resetTopic();
    resetName();
    resetNumber();
  };

  const {
    value: enteredName,
    isValid: nameIsValid,
    hasError: nameHasError,
    inputValueHandler: nameInputHandler,
    onBlurHandler: nameBlurHandler,
    inputClasses: nameInputClasses,
    reset: resetName,
  } = useInput2((value) => value.trim() !== "");

  const {
    value: enteredEmail,
    isValid: emailIsValid,
    hasError: emailHasError,
    inputValueHandler: emailInputHandler,
    onBlurHandler: emailBlurHandler,
    inputClasses: emailInputClasses,
    reset: resetEmail,
  } = useInput2((value) => value.includes("@") && value.trim() !== "@");

  const {
    value: enteredTopic,
    isValid: topicIsValid,
    hasError: topicHasError,
    inputValueHandler: topicInputHandler,
    onBlurHandler: topicBlurHandler,
    inputClasses: topicInputClasses,
    reset: resetTopic,
  } = useInput2((value) => value.trim() !== "");

  const {
    value: enteredMessage,
    isValid: messageIsValid,
    hasError: messageHasError,
    inputValueHandler: messageInputHandler,
    onBlurHandler: messageBlurHandler,
    inputClasses: messageInputClasses,
    reset: resetMessage,
  } = useInput2((value) => value.trim() !== "");

  const {
    value: enteredNumber,
    inputValueHandler: numberInputHandler,
    onBlurHandler: numberBlurHandler,
    inputClasses: numberInputClasses,
    reset: resetNumber,
  } = useInput2((value) => value.trim() !== "" && !isNaN(value));

  let formIsValid = false;

  if (nameIsValid && topicIsValid && emailIsValid && messageIsValid) {
    formIsValid = true;
  }

  return (
    <div className="card">
      <h2>Contact Wrand</h2>

      <form ref={form} onSubmit={formSubmitHandler} className="form-control">
        <div className="row">
          <div className={`${nameInputClasses} col`}>
            <input
              type="text"
              id="name"
              onChange={nameInputHandler}
              onBlur={nameBlurHandler}
              value={enteredName}
              name="user_name"
              placeholder="Name"
            />
          </div>
          <div className={`${emailInputClasses} col`}>
            <input
              type="text"
              id="email"
              onChange={emailInputHandler}
              onBlur={emailBlurHandler}
              value={enteredEmail}
              name="user_email"
              placeholder="Email"
            />
          </div>
        </div>
        <div className="row">
          <div id="topic" className={`${topicInputClasses} col`}>
            <input
              type="text"
              id="text"
              onBlur={topicBlurHandler}
              onChange={topicInputHandler}
              value={enteredTopic}
              name="topic"
              placeholder="Topic"
            />
          </div>
          <div className={`${numberInputClasses} col`}>
            <input
              type="text"
              id="number"
              onChange={numberInputHandler}
              onBlur={numberBlurHandler}
              value={enteredNumber}
              name="user_number"
              placeholder="Phone Number (Optional)"
            />
          </div>
        </div>
        <div className="row" id="message">
          <div>
            <textarea
              name="message"
              rows="4"
              id="message"
              onBlur={messageBlurHandler}
              onChange={messageInputHandler}
              value={enteredMessage}
              placeholder=" How can I help you?"
              className={`${messageInputClasses} col`}
            ></textarea>
          </div>
        </div>
        <div className="form-actions row">
          <button disabled={!formIsValid}>Submit</button>
        </div>
      </form>
    </div>
  );
};
export default BasicForm;
