import "./App.css";
import "semantic-ui-css/semantic.min.css";
import logo from "./GradLogo.png";
import BasicForm from "./components/BasicForm";

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <h3> This is the website of Wrand Villafuerte </h3>{" "}
        <h4>It is currently under construction.</h4>
        <img src={logo} className="App-logo" alt="logo" />{" "}
        <h4>For inquiries fill out the form below.</h4>
        <BasicForm />
      </header>
      <body></body>
    </div>
  );
}

export default App;
